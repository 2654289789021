<template>
	<div>
		<base-data-table
			v-bind="currentTableAttrs"
			:title="$t('invoices')"
			:add="false"
			:translate="false"
			:tabSliders="tabSliders"
			:tab-slider.sync="tabSlider"
			@view="$router.push(`invoices/${$event.id}`)"
		>
			<!-- @status="onChangeStatus" -->
			<template #search>
				<v-btn
					depressed
					height="48"
					color="primary"
					@click="onToggleSearch"
				>
					<v-icon v-if="search.isExpanded">mdi-close</v-icon>
					<v-icon v-else>mdi-filter</v-icon>
				</v-btn>
			</template>

			<template v-if="search.isExpanded" #extension>
				<div class="tw-bg-gray-200 tw-p-4 tw-mx-4 tw-rounded tw-mb-3">
					<div class="tw-grid tw-gap-4 tw-grid-cols-3">
						<v-text-field
							v-model="search.form.invoiceNumber"
							outlined
							clearable
							hide-details
							:label="$t('invoice-number')"
							placeholder=""
						/>
						<v-text-field
							v-model="search.form.companyName"
							outlined
							clearable
							hide-details
							:label="$t('company-supplier-name')"
							placeholder=""
						/>
						<v-text-field
							v-model="search.form.registrationNumber"
							outlined
							clearable
							hide-details
							:label="$t('commercial-registration-no')"
							placeholder=""
						/>
						<v-select
							v-model="search.form.status"
							:items="InvoiceStatuses"
							outlined
							multiple
							clearable
							hide-details
							:label="$t('statusText')"
						/>
						<div class="tw-flex tw-space-x-4 tw-col-span-2">
							<base-date-picker
								v-model="search.form.fromDate"
								:append-icon="false"
								:placeholder="$t('from-date')"
								prepend-inner-icon
								class="tw-flex-1"
							/>
							<base-date-picker
								v-model="search.form.toDate"
								:append-icon="false"
								:placeholder="$t('to-date')"
								class="tw-flex-1"
							/>
						</div>
					</div>
					<div
						class="tw-flex tw-justify-end tw-space-x-2 tw-items-end tw-mt-4"
					>
						<v-btn
							text
							height="40"
							color="primary"
							@click="onToggleSearch"
						>
							<span class="">{{ $t('close') }}</span>
						</v-btn>
						<v-btn
							text
							height="40"
							color="primary"
							@click="onResetSearch"
						>
							<span class="">{{ $t('reset') }}</span>
						</v-btn>
						<base-button
							:loading="search.isLoading"
							@click="onSearchInvoices"
						>
							{{ $t('actions.search') }}
						</base-button>
					</div>
				</div>
			</template>

			<template v-slot:[`item.serial`]="{ index }">
				{{
					currentTableAttrs.provider.perPage *
						(currentTableAttrs.provider.currentPage - 1) +
					index +
					1
				}}
			</template>

			<template v-slot:[`item.phone`]="{ item }">
				<base-phone-number
					:phone="$get(item, 'contractable.company.phone')"
					class="tw-whitespace-nowrap tw-flex tw-items-center tw-space-s-3"
				>
					<template #default="{ phone, country }">
						<v-avatar v-if="country" size="20">
							<base-flag height="20" width="20" :code="country" />
						</v-avatar>
						<span>{{ phone }}</span>
					</template>
				</base-phone-number>
			</template>

			<template v-slot:[`item.status`]="{ item }">
				<BaseStatus :status="item.status" />
				<!-- <BaseStatus v-if="$currentUser.isBuyer" :status="item.status" />
				<BaseStatusChanger
					v-else
					:height="32"
					:translate="false"
					:value="item.status"
					:items="InvoiceStatuses"
					:on-change="onChangeStatus"
				/> -->
			</template>
		</base-data-table>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import {
	// ROLE,
	InvoiceStatuses,
} from '../../consts'

import {
	omitEmpties,
	//toFormData
} from 'vuelpers'

export default {
	name: 'InvoicesView',
	data: () => ({
		InvoiceStatuses,
		tabSlider: 'buyer',
		search: {
			isLoading: false,
			isExpanded: false,
			form: {},
		},
	}),
	created() {
		this.search.form = this.initialSearchForm()

		const { isBuyer, isSupplier } = this.$currentUser
		this.tabSlider = isSupplier
			? 'supplier'
			: isBuyer
			? 'buyer'
			: this.tabSlider
	},
	computed: {
		...mapGetters('auth', ['$currentUser']),
		...mapGetters('invoices', ['$buyerInvoices', '$supplierInvoices']),
		...mapGetters('contracts', [
			'$buyerContracts',
			'$supplierContracts',
			'$suppliers',
			'$buyers',
		]),
		tabSliders() {
			if (!this.$currentUser.isAdmin) return []
			return [
				{ text: "Buyer's", value: 'buyer' },
				{ text: "Supplier's", value: 'supplier' },
			]
		},
		currentTableAttrs() {
			return {
				buyer: {
					name: 'BUYER',
					actions: this.actions,
					headers: this.headers,
					provider: this.$buyerInvoices,
					fetchFunction: this.onFetchBuyerInvoices,
				},
				supplier: {
					name: 'SUPPLIER',
					actions: this.actions,
					headers: this.headers,
					provider: this.$supplierInvoices,
					fetchFunction: this.onFetchSupplierInvoices,
				},
			}[this.tabSlider]
		},
		actions() {
			return [
				{
					icon: 'mdi-eye-outline',
					event: 'view',
					text: this.$t('view-details'),
				},
				// {
				// 	icon: 'mdi-checkbox-intermediate',
				// 	event: 'status',
				// 	text: (invoice) => {
				// 		return invoice.status
				// 			? this.$t('cancel_the_contract')
				// 			: 'Activate'
				// 	},
				// },
			]
		},
		headers() {
			return [
				{ text: 'No.', value: 'serial' },
				{ text: this.$t('invoice-number-0'), value: 'invoiceNumber' },
				{
					text: this.$t('amount'),
					value: 'total',
					align: 'end',
					computed: ({ total }) => `SAR ${total.toFixed(2)}`,
				},
				{
					text: this.$t('invoice-date'),
					value: 'datetime',
					computed: ({ datetime }) => {
						return this.$m(datetime).format('DD-MM-YYYY')
					},
				},
				{
					text: this.$t('headers.companyEntityName'),
					value: 'invoiceable.company.name',
				},
				{ text: this.$t('mobile'), value: 'invoiceable.company.phone' },
				{ text: this.$t('city'), value: 'invoiceable.address.0.city' },
				{
					text: this.$t('com-reg-no'),
					value: 'invoiceable.company.registrationNumber',
				},
				{
					text: this.$t('statusText'),
					value: 'status',
					// computed: ({ status }) => {
					// 	return (
					// 		this.InvoiceStatuses.get(status)?.text ||
					// 		'Awaiting Payment'
					// 	)
					// },
				},
				{ text: this.$t('headers.actions'), value: 'actions' },
			]
		},
	},
	methods: {
		...mapActions('contracts', ['updateContract']),
		...mapActions('invoices', [
			'fetchBuyerInvoices',
			'fetchSupplierInvoices',
		]),
		initialSearchForm() {
			return {
				status: '',
				toDate: '',
				fromDate: '',
				companyName: '',
				invoiceNumber: '',
				registrationNumber: '',
				invoiceableId: this.$currentUser.buySup?.id,
			}
		},
		onFetchBuyerInvoices(query = {}) {
			return this.fetchBuyerInvoices({
				page: this.$buyerInvoices.currentPage,
				perPage: this.$buyerInvoices.perPage,
				...omitEmpties({ filter: this.search.form }),
				...query,
			})
		},
		onFetchSupplierInvoices(query = {}) {
			return this.fetchSupplierInvoices({
				page: this.$supplierInvoices.currentPage,
				perPage: this.$supplierInvoices.perPage,
				...omitEmpties({ filter: this.search.form }),
				...query,
			})
		},
		onFetchInvoices(query = {}) {
			return this.currentTableAttrs.fetchFunction(query)
		},
		onToggleSearch() {
			this.search.isExpanded = !this.search.isExpanded
			if (!this.search.isExpanded) this.onResetSearch()
		},
		onSearchInvoices() {
			return this.onFetchInvoices({ page: 1 })
		},
		onResetSearch() {
			this.search.form = this.initialSearchForm()
			this.onSearchInvoices()
		},
		// onChangeStatus(e) {
		// 	const isSupplier = e.contractableType.includes(this.$t('supplierText'))
		// 	this.handleUpdateContract(
		// 		{
		// 			id: e.id,
		// 			contractableId: e.contractableId,
		// 			contractableType: isSupplier ? ROLE.SUPPLIER : ROLE.BUYER,
		// 			status: !e.status,
		// 		},
		// 		{
		// 			title: 'Contract Activate / Cancel',
		// 			message: `Are you sure you want to ${
		// 				e.status ? 'cancel' : 'activate'
		// 			} this contract?`,
		// 		}
		// 	)
		// },
		// handleUpdateContract(data, config = {}) {
		// 	this.$root.confirmation = {
		// 		title: 'Update Confirmation',
		// 		message: 'Are you sure you want to update this contract?',
		// 		...config,
		// 		handler: async () => {
		// 			let [err] = await this.updateContract(
		// 				toFormData(data, {
		// 					_method: 'PATCH',
		// 					convertCase: 'snake_case',
		// 				})
		// 			)
		// 			if (err) {
		// 				return this.$toast.error(
		// 					err.message ||
		// 						this.$t('error-updating', [this.$t('contract')])
		// 				)
		// 			}
		// 			this.$toast.success(
		// 				this.$t('success-update', [this.$t('contract')])
		// 			)
		// 			this.onResetContractForm()
		// 		},
		// 	}
		// },
	},
}
</script>
